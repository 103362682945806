import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useAddOrUpdateSubjectMutation } from "../hooks/subject";

const AddSubjectModal = ({ show, handleClose, edittingSubject }) => {
  // const {data} = useAdminByEmailQuery()
  const { mutate: addSubject } = useAddOrUpdateSubjectMutation();
  const [formData, setFormData] = useState({
    subject_name: "",
    status: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const resetFormData = () => {
    setFormData({
      subject_name: "",
      status: "",
    });
  };

  useEffect(() => {
    if (edittingSubject) {
      setFormData({
        subject_id: edittingSubject.subject_id,
        subject_name: edittingSubject.subject_name,
        status: edittingSubject.status,
      });
    }
  }, [edittingSubject]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      await addSubject(formData, edittingSubject?.subject_id);
      handleClose();
      toast.success("Added successfully");
      resetFormData();
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div
      className={`modal ${show ? "show d-block" : ""}`}
      role="dialog"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add New Admin</h5>
            <button type="button" className="close" onClick={handleClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="box-body">
                <div className="row">
                  <div className="form-group no-margin col-md-6">
                    <label>
                      Subject Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="subject_name"
                      className="form-control"
                      placeholder="Subject Name"
                      value={formData.subject_name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group no-margin col-md-6">
                    <label>Status</label>
                    <select
                      className="form-control"
                      name="status"
                      value={formData.status}
                      onChange={handleChange}
                    >
                      <option value="">Select Status</option>
                      <option value="ACTIVE">Active</option>
                      <option value="INACTIVE">Inactive</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="box-footer">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSubjectModal;
