/** @format */

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import apiClient from "../apiClient";

// Hook for fetching all teachers
export const useSubjectQuery = () => {
  return useQuery({
    queryKey: ["subjects"],
    queryFn: async () => {
      const { data } = await apiClient.get("api/subjects/all-subjects");
      return data;
    },
  });
};

// Hook for adding a new class
export const useAddSubjectMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (admin) =>
      (await apiClient.post("api/subjects/add-subject", admin)).data,
    onSuccess: () => {
      queryClient.invalidateQueries(["subjects"]);
    },
  });
};
// Hook for adding or updating a subject
export const useAddOrUpdateSubjectMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (subject, subject_id) => {
      return (
        await apiClient.post(
          "api/subjects/add-or-update-subject",
          subject,
          subject_id
        )
      ).data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["subjects"]);
    },
  });
};
// Hook for deleting a subject
export const useDeleteSubjectMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (subject_id) => {
      return (
        await apiClient.delete(`api/subjects/delete-subject/${subject_id}`)
      ).data;
    },
    onSuccess: () => {
      // Invalidate the subjects query to refresh the list after deletion
      queryClient.invalidateQueries(["subjects"]);
    },
  });
};
