import React from "react";
import { useParams } from "react-router-dom"; // Import useParams
import SideBar from "../Components/SideBar";
import { useAdminByIdQuery } from "../hooks/admin";

const AdminProfile = () => {
  const { id } = useParams();

  const { data: adminData, isLoading, error } = useAdminByIdQuery(id);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching admin data</div>;
  }

  const formateTime = (time) => {
    const formattedTime = new Date(time).toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return formattedTime;
  };

  return (
    <SideBar>
      <section className="container-fluid">
        <div className="card p-4">
          <div className="row">
            {/* Left Column */}
            <div className="col-md-6">
              <div className="box box-primary">
                <div className="box-body box-profile">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <img
                        className="profile-user-img img-responsive img-circle"
                        src="/img/person.jfif"
                        alt="User profile"
                        style={{ width: "100px", height: "100px" }}
                      />
                    </div>
                  </div>
                  <h3 className="profile-username text-center">
                    {adminData.full_name}
                  </h3>
                  <p className="text-muted text-center">Staff</p>
                  <ul className="list-group list-group-unbordered">
                    <li className="list-group-item">
                      <div className="row">
                        <div className="col-md-6">
                          <b>UserID</b>
                        </div>
                        <div className="col-md-6 text-right">
                          <span className="pull-right">{adminData.id}</span>
                        </div>
                      </div>
                    </li>
                    <li className="list-group-item">
                      <div className="row">
                        <div className="col-md-6">
                          <b>Status</b>
                        </div>
                        <div className="col-md-6 text-right">
                          <span
                            className={`badge ${
                              adminData.status === "Active" || "ACTIVE"
                                ? "bg-success text-white"
                                : "bg-secondary text-white"
                            }`}
                          >
                            {adminData.status}
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="col-md-6">
              <div className="box box-primary">
                <div className="box-header with-border">
                  <h3 className="box-title">About Admin</h3>
                </div>
                <div className="box-body">
                  <strong>
                    <i className="fa fa-user margin-r-5"></i> Full Name
                  </strong>
                  <p className="text-muted">{adminData.full_name}</p>
                  <hr />
                  <strong>
                    <i className="fa fa-history margin-r-5"></i> DOB
                  </strong>
                  <p className="text-muted">
                    {adminData &&
                      new Date(adminData.date_of_birth).toLocaleDateString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        }
                      )}
                  </p>
                  <hr />
                  <strong>
                    <i className="fa fa-intersex margin-r-5"></i> Gender
                  </strong>
                  <p className="text-muted">{adminData.gender}</p>
                  <hr />
                  <strong>
                    <i className="fa fa-book margin-r-5"></i> Religion
                  </strong>
                  <p className="text-muted">{adminData.religion}</p>
                  <hr />
                  <strong>
                    <i className="fa fa-envelope margin-r-5"></i> Email
                  </strong>
                  <p className="text-muted">{adminData.email}</p>
                  <hr />
                  <strong>
                    <i className="fa fa-pencil margin-r-5"></i> Registered on
                  </strong>
                  {new Date(adminData.createdAt).toLocaleDateString()}{" "}
                  {formateTime(adminData.createdAt)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </SideBar>
  );
};

export default AdminProfile;
