import React, { useState, useEffect } from "react";
import { useSavePsychomotorMutation } from "../hooks/admin";
import { useParams } from "react-router-dom";
import { useStudentByIdQuery } from "../hooks/studentHook";
import SideBar from "../Components/SideBar";
import toast from "react-hot-toast";

const BEHAVIORS = [
  "Aesthetic",
  "Creativity",
  "Honesty",
  "Responsibility",
  "Initiative",
  "Perseverance",
  "Leadership",
  "Obedience",
  "Politeness",
  "Self Control",
];

const PSYCHOMOTOR_SKILLS = [
  "Communication",
  "Handwriting",
  "Musical Skills",
  "Handling of Tools",
  "Painting/Drawing",
  "Sports/Game",
];

const RATINGS = ["Excellent", "Very Good", "Good", "Poor", "Very Poor"];

function PsychomotorPage() {
  const { id } = useParams(); // Fetch the student ID from the URL params
  const { data: studentData, isLoading, error } = useStudentByIdQuery(id);
  const { mutate: savePsychomotor, isLoading: isSaving } =
    useSavePsychomotorMutation();

  const [formData, setFormData] = useState({
    studentId: id || "",
    class: "",
    partA: BEHAVIORS.reduce(
      (acc, behavior) => ({ ...acc, [behavior]: "" }),
      {}
    ),
    partB: PSYCHOMOTOR_SKILLS.reduce(
      (acc, skill) => ({ ...acc, [skill]: "" }),
      {}
    ),
    classTeacherRemark: "",
    headTeacherRemark: "",
  });

  useEffect(() => {
    if (studentData) {
      setFormData((prev) => ({
        ...prev,
        studentId: studentData.userid,
        class: studentData.class,
      }));
    }
  }, [studentData]);

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleRatingChange = (part, key, value) => {
    setFormData((prev) => ({
      ...prev,
      [part]: { ...prev[part], [key]: value },
    }));
  };

  const handleSubmit = () => {
    if (!formData.studentId || !formData.class) {
      toast.error("temporarily unavailable");
      return;
    }

    savePsychomotor(formData, {
      onSuccess: () => toast.success("psycho saved"),
      onError: (error) => alert(`Error saving data: ${error.message}`),
    });
  };

  if (isLoading) {
    return (
      <SideBar>
        <p>Loading student data...</p>
      </SideBar>
    );
  }

  if (error) {
    return (
      <SideBar>
        <p>Error loading student data: {error.message}</p>
      </SideBar>
    );
  }

  return (
    <SideBar>
      <div className="container mt-4">
        <h2 className="text-center mb-4">Psychomotor Evaluation</h2>
        <div className="mb-3">
          <p>
            <strong>Student ID:</strong> {formData.studentId}
          </p>
          <p>
            <strong>Class:</strong> {formData.class}
          </p>
        </div>
        <h3>PART A: Behavior</h3>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Behavior</th>
              <th>Rating</th>
            </tr>
          </thead>
          <tbody>
            {BEHAVIORS.map((behavior) => (
              <tr key={behavior}>
                <td>{behavior}</td>
                <td>
                  <select
                    className="form-select"
                    value={formData.partA[behavior]}
                    onChange={(e) =>
                      handleRatingChange("partA", behavior, e.target.value)
                    }
                  >
                    <option value="">Select Rating</option>
                    {RATINGS.map((rating) => (
                      <option key={rating} value={rating}>
                        {rating}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <h3>PART B: Psychomotor Skills</h3>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Psychomotor Skill</th>
              <th>Rating</th>
            </tr>
          </thead>
          <tbody>
            {PSYCHOMOTOR_SKILLS.map((skill) => (
              <tr key={skill}>
                <td>{skill}</td>
                <td>
                  <select
                    className="form-select"
                    value={formData.partB[skill]}
                    onChange={(e) =>
                      handleRatingChange("partB", skill, e.target.value)
                    }
                  >
                    <option value="">Select Rating</option>
                    {RATINGS.map((rating) => (
                      <option key={rating} value={rating}>
                        {rating}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <h3>Remarks</h3>
        <div className="mb-3">
          <label htmlFor="classTeacherRemark" className="form-label">
            Class Teacher Remark
          </label>
          <textarea
            id="classTeacherRemark"
            className="form-control"
            value={formData.classTeacherRemark}
            onChange={(e) =>
              handleInputChange("classTeacherRemark", e.target.value)
            }
          />
        </div>
        <div className="mb-3">
          <label htmlFor="headTeacherRemark" className="form-label">
            Head Teacher Remark
          </label>
          <textarea
            id="headTeacherRemark"
            className="form-control"
            value={formData.headTeacherRemark}
            onChange={(e) =>
              handleInputChange("headTeacherRemark", e.target.value)
            }
          />
        </div>
        <div className="text-center mt-4">
          <button
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={isSaving}
          >
            {isSaving ? "Saving..." : "Save Evaluation"}
          </button>
        </div>
      </div>
    </SideBar>
  );
}

export default PsychomotorPage;
