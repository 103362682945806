/** @format */

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import apiClient from "../apiClient";

// Hook for fetching all admins
export const useAdminQuery = () => {
  return useQuery({
    queryKey: ["admins"],
    queryFn: async () => {
      const { data } = await apiClient.get("api/admins/all-admin");
      return data;
    },
  });
};

// Hook for fetching a single admin by email
export const useAdminByEmailQuery = (email) => {
  return useQuery({
    queryKey: ["admin", email],
    queryFn: async () => {
      const { data } = await apiClient.get(`api/admins/admin/${email}`);
      return data;
    },
    enabled: !!email, // only runs the query if email is defined
  });
};

// Hook for fetching a single admin by id
export const useAdminByIdQuery = (id) => {
  return useQuery({
    queryKey: ["admin", id],
    queryFn: async () => {
      const { data } = await apiClient.get(`api/admins/admin/single/${id}`);
      return data;
    },
    enabled: !!id, // only runs the query if id is defined
  });
};

// Hook for fetching homepage data
export const useHomepageQueryData = () => {
  return useQuery({
    queryKey: ["homepage-query"], // Unique key for the query
    queryFn: async () => {
      const { data } = await apiClient.get("api/admins/get-homepage-data");
      return data;
    },
  });
};

// Hook for fetching homepage post data
export const useHomepostQueryData = () => {
  return useQuery({
    queryKey: ["homepage-data"], // Unique key for the query
    queryFn: async () => {
      const { data } = await apiClient.get("api/admins/get-homepost-data");
      return data;
    },
  });
};

// Hook for adding a new class
export const useAddAdminMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (admin) =>
      (await apiClient.post("api/admins/add-admin", admin)).data,
    onSuccess: () => {
      queryClient.invalidateQueries(["admins"]);
    },
  });
};

// Hook for adding or updating a student
export const useAddOrUpdateStudentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (student, userid) => {
      return (
        await apiClient.post(
          "api/admins/add-or-update-student",
          student,
          userid
        )
      ).data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["students"]);
    },
  });
};
// Hook for adding or updating a student
export const useAddOrUpdateAdminMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (admin, email) => {
      return (
        await apiClient.post("api/admins/add-or-update-admin", admin, email)
      ).data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["admins"]);
    },
  });
};
// Hook for saving attendance
export const useSaveAttendanceMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (attendanceData) => {
      return (
        await apiClient.post(
          "api/admins/add-or-update-attendance",
          attendanceData
        )
      ).data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["attendance"]);
    },
  });
};

export const useSavePsychomotorMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (psychomotorData) => {
      const response = await apiClient.post(
        "/api/admins/add-or-update-psychomotor",
        psychomotorData
      );
      return response.data; // Return the response data
    },
    onSuccess: () => {
      // Optionally refetch or invalidate queries to keep data fresh
      queryClient.invalidateQueries(["psychomotor"]);
    },
    onError: (error) => {
      // Handle error (optional, for better UX)
      console.error("Error saving psychomotor data:", error);
    },
  });
};

// Hook to fetch psychomotor data
export const usePsychomotorByStudentAndClassQuery = (
  studentId,
  studentClass
) => {
  return useQuery({
    queryKey: ["psychomotor", studentId, studentClass],
    queryFn: async () => {
      if (!studentId || !studentClass) {
        throw new Error("studentId and class are required");
      }

      const response = await apiClient.get("/api/admins/get-psychomotor", {
        params: { studentId, class: studentClass },
      });
      return response.data;
    },
    enabled: !!studentId && !!studentClass, // Only run the query if these values are provided
  });
};

// Hook for updating homepage data
export const useUpdateHomepageDataMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (homepageData) =>
      (await apiClient.post("api/admins/update-homepage-data", homepageData))
        .data,
    onSuccess: () => {
      queryClient.invalidateQueries(["homepagedata"]);
    },
  });
};
// Hook for updating homepage data
export const useUpdateHomepagePostDataMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (homepagePostData) =>
      (await apiClient.post("api/admins/update-post", homepagePostData)).data,
    onSuccess: () => {
      queryClient.invalidateQueries(["homepagedata"]);
    },
  });
};
// Hook for adding a new class
export const useTermMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (term) =>
      (await apiClient.post("api/admins/add-term", term)).data,
    onSuccess: () => {
      queryClient.invalidateQueries(["terms"]);
    },
  });
};
// Hook for fetching all terms
export const useGetTermsQuery = () => {
  return useQuery({
    queryKey: ["terms"],
    queryFn: async () => {
      const { data } = await apiClient.get("api/admins/terms");
      return data;
    },
  });
};

// Hook for deleting a term
export const useDeleteTermMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id) => {
      const { data } = await apiClient.delete(`api/admins/terms/${id}`);
      return data;
    },
    onSuccess: () => {
      // Invalidate and refetch the terms after a successful deletion
      queryClient.invalidateQueries(["terms"]);
    },
    onError: (error) => {
      console.error("Error deleting term:", error);
    },
  });
};

// Hook for fetching all terms
export const useGetAttendanceQuery = () => {
  return useQuery({
    queryKey: ["attendance"],
    queryFn: async () => {
      const { data } = await apiClient.get("api/admins/attendance");
      return data;
    },
  });
};
// Hook for updating school settings
export const useUpdateSchoolSettingsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (schoolSettings) =>
      (
        await apiClient.post(
          "api/admins/update-school-settings",
          schoolSettings
        )
      ).data,
    onSuccess: () => {
      queryClient.invalidateQueries(["schoolSettings"]);
    },
  });
};
// Hook to fetch all school settings

export const useFetchSchoolSettings = () => {
  return useQuery({
    queryKey: ["schoolSettings"],
    queryFn: async () => {
      const { data } = await apiClient.get("api/admins/get-school-settings");
      return data;
    },
  });
};
