import React from "react";
import { NavLink } from "react-router-dom";

function TeacherSideBarItems({ style, changeStyle }) {
  return (
    <ul className={style} id="accordionSidebar">
      {/*  <!-- Sidebar - Brand --> */}
      <div className="d-flex align-items-center justify-content-center align-items-center">
        <NavLink
          className="sidebar-brand d-flex align-items-center justify-content-center"
          to="#"
        >
          <img
            className="h-100 w-100 rounded-circle"
            src="img/undraw_profile.svg"
            alt=""
          />

          <div className="sidebar-brand-text mx-3">Teacher</div>
        </NavLink>
        <div className="text-center d-none d-md-inline">
          <button
            className="rounded-circle border-0"
            id="sidebarToggle"
            onClick={changeStyle}
          ></button>
        </div>
      </div>

      {/*   <!-- Divider --> */}
      <hr className="sidebar-divider my-0" />

      {/*  <!-- Nav Item - Dashboard --> */}
      <li className="nav-item active">
        <NavLink className="nav-link" to="/dashboard">
          <i className="fas fa-fw fa-tachometer-alt"></i>
          <span>Dashboard</span>
        </NavLink>
      </li>

      {/*  <!-- Divider --> */}
      <hr className="sidebar-divider" />
      <li className="nav-item">
        <NavLink className="nav-link" to="/classandsubject">
          <i class="fas fa-home text-white"></i>
          <span>Class & Subjects</span>
        </NavLink>
      </li>

      <li className="nav-item">
        <NavLink className="nav-link" to="/teacherstudents">
          <i class="fas fa-users text-white"></i>
          <span>Students</span>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" href="tables.html">
          <i class="fas fa-table text-white"></i>
          <span>Exam Timetable</span>
        </NavLink>
      </li>

      <li className="nav-item">
        <NavLink
          className="nav-link collapsed"
          to="#"
          data-toggle="collapse"
          data-target="#collapseExaminations"
          aria-expanded="true"
          aria-controls="collapseExaminations"
        >
          <i className="fas fa-book text-white"></i>
          <span>Examinations</span>
        </NavLink>
        <div
          id="collapseExaminations"
          className="collapse"
          aria-labelledby="headingExaminations"
          data-parent="#accordionSidebar"
        >
          <div className="bg-white py-2 collapse-inner rounded">
            <NavLink className="collapse-item text-dark" to="/markregister">
              Mark Register
            </NavLink>

            <NavLink className="collapse-item text-dark" to="/teachersresult">
              Exam Result
            </NavLink>

            <div className="collapse-divider"></div>
          </div>
        </div>
      </li>

      <li className="nav-item">
        <NavLink
          className="nav-link collapsed"
          to="#"
          data-toggle="collapse"
          data-target="#collapseCummalativeExam"
          aria-expanded="true"
          aria-controls="collapseCummalativeExam"
        >
          <i className="fas fa-plus text-white"></i>
          <span>Cummulative</span>
        </NavLink>
        <div
          id="collapseCummalativeExam"
          className="collapse"
          aria-labelledby="headingCummalativeExam"
          data-parent="#accordionSidebar"
        >
          <div className="bg-white py-2 collapse-inner rounded">
            <NavLink className="collapse-item text-dark" to="/cummulativeexam">
              Cummulative Exam
            </NavLink>
            <div className="collapse-divider"></div>
          </div>
        </div>
      </li>

      <li className="nav-item">
        <NavLink
          className="nav-link collapsed"
          to="#"
          data-toggle="collapse"
          data-target="#collapseAttendance"
          aria-expanded="true"
          aria-controls="collapseAttendance"
        >
          <i class="fas fa-users text-white"></i>
          <span>Attendance</span>
        </NavLink>
        <div
          id="collapseAttendance"
          className="collapse"
          aria-labelledby="headingAttendance"
          data-parent="#accordionSidebar"
        >
          <div className="bg-white py-2 collapse-inner rounded">
            <NavLink className="collapse-item text-dark" to="/classattendance">
              Class Attendance
            </NavLink>
            <NavLink className="collapse-item text-dark" to="/attendancelist">
              Attendance Report
            </NavLink>
          </div>
        </div>
      </li>

      <li className="nav-item">
        <NavLink className="nav-link" href="tables.html">
          <i class="fas fa-home text-white"></i>
          <span>Home Work</span>
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" href="tables.html">
          <i class="fas fa-eye text-white"></i>
          <span>Notice Board</span>
        </NavLink>
      </li>

      <li className="nav-item">
        <NavLink
          className="nav-link collapsed"
          to="#"
          data-toggle="collapse"
          data-target="#collapseProfile"
          aria-expanded="true"
          aria-controls="collapseProfile"
        >
          <i className="fas fa-user text-white"></i>
          <span>Profile</span>
        </NavLink>
        <div
          id="collapseProfile"
          className="collapse"
          aria-labelledby="headingProfile"
          data-parent="#accordionSidebar"
        >
          <div className="bg-white py-2 collapse-inner rounded">
            <NavLink className="collapse-item text-dark" to="/teacher-profile">
              View
            </NavLink>

            <div className="collapse-divider"></div>
          </div>
        </div>
      </li>
    </ul>
  );
}

export default TeacherSideBarItems;
