/** @format */

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import apiClient from "../apiClient";

// Hook for fetching all teachers
export const useTeachersQuery = () => {
  return useQuery({
    queryKey: ["teachers"],
    queryFn: async () => {
      const { data } = await apiClient.get("api/teachers/all-teachers");
      return data;
    },
  });
};

// Hook for adding a new teacher
export const useAddTeacherMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (teacher, userid) => {
      return (await apiClient.post("api/teachers/add-teacher", teacher, userid))
        .data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["teachers"]);
    },
  });
};

// Hook for fetching a single teacher by email
export const useTeacherByEmailQuery = (email) => {
  return useQuery({
    queryKey: ["teacher", email],
    queryFn: async () => {
      const { data } = await apiClient.get(
        `api/teachers/teacher/single/${email}`
      );
      return data;
    },
    enabled: !!email, // only runs the query if email is defined
  });
};

// Hook for fetching a single teacher by userid
export const useTeacherQuery = (userid) => {
  return useQuery({
    queryKey: ["teacher", userid],
    queryFn: async () => {
      const { data } = await apiClient.get(`api/teachers/teacher/${userid}`);
      return data;
    },
    enabled: !!userid, // only runs the query if userid is defined
  });
};

// Hook for editing a teacher
export const useEditClassMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id, updated }) =>
      (await apiClient.put(`api/teachers/${id}`, updated)).data,
    onSuccess: () => {
      queryClient.invalidateQueries(["teachers"]);
    },
  });
};

// Hook for deleting a teacher
export const useDeleteClassMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id) =>
      (await apiClient.delete(`api/teachers/${id}`)).data,
    onSuccess: () => {
      queryClient.invalidateQueries(["teachers"]);
    },
  });
};
