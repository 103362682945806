import { useState } from "react";
import SideBar from "../Components/SideBar";
import ReactPaginate from "react-paginate";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { CgSpinner } from "react-icons/cg";
import toast from "react-hot-toast";
import {
  useDeleteStudentMutation,
  useStudentsQuery,
} from "../hooks/studentHook";
import AddStudentModal from "../Components/AddStudentModal";
import { NavLink } from "react-router-dom";

const ITEMS_PER_PAGE = 10;

function Students() {
  const [currentPage, setCurrentPage] = useState(0);
  const [editingStudent, setEditingStudent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term

  const { data: students, isPending, isError, error } = useStudentsQuery();
  const { mutateAsync: deleteStudent } = useDeleteStudentMutation();

  const handleDelete = (userid) => {
    if (window.confirm("Are you sure you want to delete this student?")) {
      deleteStudent(userid);
    }
  };

  const handleShowModal = (term = null) => {
    setEditingStudent(term);
    setShowModal(true);
  };

  // Handle editing term
  const handleEditStudent = (term) => {
    handleShowModal(term);
  };

  // Filtering students based on search term
  const filteredStudents = students.filter((student) =>
    student.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const startOffset = currentPage * ITEMS_PER_PAGE;
  const endOffset = startOffset + ITEMS_PER_PAGE;
  const currentItems = filteredStudents.slice(startOffset, endOffset);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingStudent(null);
  };

  if (isPending) {
    return <CgSpinner size={25} />;
  }

  if (isError) {
    return toast.error(error.message);
  }

  if (!students || students.length === 0) {
    return <div>No students found.</div>;
  }

  return (
    <SideBar>
      <div className="container-fluid">
        <div className="card w-100 py-2 px-2 mb-3">
          <div className="box-header with-border">
            <h3 className="box-title text-black">Search Student</h3>
          </div>
          <form action="" method="get">
            <div className="box-body">
              <div className="row">
                <div className="form-group no-margin col-md-4">
                  <label for="exampleInputEmail1">Name</label>
                  <input
                    type="text"
                    value={searchTerm} // Bind the input value to the search term state
                    onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
                    className="form-control"
                    placeholder="Search name"
                  />
                </div>
                <div className="form-group no-margin col-md-4">
                  <label for="exampleInputEmail1">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search email"
                  />
                </div>
                <div
                  className="form-group no-margin col-md-4"
                  style={{ paddingTop: "33px" }}
                >
                  <button type="submit" className="btn btn-primary">
                    Search
                  </button>
                  <button
                    type="button"
                    className="btn ml-2 btn-success"
                    onClick={() => setSearchTerm("")}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </form>

          {/* Student List and Modal */}
          <div className="row" style={{ overflowX: "auto" }}>
            <div className="col-md-12">
              <div className="px-4 d-flex justify-content-between align-items-center">
                <p className="mb-0">Student List ({filteredStudents.length})</p>
                <button
                  className="btn btn-success btn-sm"
                  onClick={() => handleShowModal(null)}
                >
                  Add Student
                </button>
              </div>
            </div>
            <AddStudentModal
              show={showModal}
              handleClose={handleCloseModal}
              editingStudent={editingStudent}
            />
            {isPending && <CgSpinner size={25} />}
            <table className="table table-hover">
              <thead className="thead-light">
                <tr>
                  <th className="text-center">USERID</th>
                  <th className="text-center">PHOTO</th>
                  <th>USER NAME</th>
                  <th>CLASS</th>
                  <th className="text-center">LAST SEEN</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item) => (
                  <tr key={item.SN}>
                    <td className="text-center">{item.userid}</td>
                    <td className="text-center">
                      {item.profile_pic ? (
                        <img
                          className="rounded-circle"
                          style={{ width: "50px", height: "50px" }}
                          src={item.profile_pic}
                          alt="student"
                        />
                      ) : (
                        <img
                          className="rounded-circle"
                          style={{ width: "50px", height: "50px" }}
                          src="img/undraw_profile.svg"
                          alt="student"
                        />
                      )}
                    </td>
                    <td>{item.username}</td>
                    <td>{item.class}</td>
                    <td className="text-center">{item.last_seen}</td>
                    <td className="d-flex justify-content-end">
                      <button
                        className="btn btn-success btn-sm"
                        onClick={() => handleEditStudent(item)}
                      >
                        EDIT
                      </button>
                      <button
                        className="btn btn-danger ml-2 btn-sm"
                        onClick={() => handleDelete(item.userid)}
                      >
                        DELETE
                      </button>
                      <NavLink to={`/studentprofile/${item.userid}`}>
                        <button
                          className="btn btn-warning btn-sm ml-2"
                          style={{
                            height: "30px",
                            fontSize: "10px",
                            width: "100px",
                          }}
                        >
                          VIEW PROFILE
                        </button>
                      </NavLink>
                      <NavLink to={`/psychomotor/${item.userid}`}>
                        <button className="btn btn-primary btn-sm ml-2">
                          PSYCHO
                        </button>
                      </NavLink>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <ReactPaginate
            previousLabel={<FaArrowLeft />}
            nextLabel={<FaArrowRight />}
            breakLabel="..."
            pageCount={Math.ceil(filteredStudents.length / ITEMS_PER_PAGE)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination-container pagination justify-content-end mt-3"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            activeClassName="active"
          />
        </div>
      </div>
    </SideBar>
  );
}

export default Students;
