import React, { useEffect, useState } from "react";

import toast from "react-hot-toast";
import { NavLink } from "react-router-dom";
import SideBar from "../Components/SideBar";
import { useGetTermsQuery } from "../hooks/admin";
import { useClassesQuery } from "../hooks/classHook";
import { useGetResultsQuery, useUpdateResultMutation } from "../hooks/result";
import { useStudentsQuery } from "../hooks/studentHook";

const ExamResult = () => {
  const { data: classes } = useClassesQuery();
  const { data: students } = useStudentsQuery();
  const { mutateAsync: addResult } = useUpdateResultMutation();
  const { data: terms } = useGetTermsQuery();
  const { data: results } = useGetResultsQuery();

  const [subjects, setSubjects] = useState([]);
  const [marksData, setMarksData] = useState({});
  const [selectedTerm, setSelectedTerm] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [filterApplied, setFilterApplied] = useState(false);
  const [filteredStudents, setFilteredStudents] = useState([]);

  useEffect(() => {
    if (classes) {
      const allSubjects = classes.reduce((acc, cls) => {
        const subjectsInClass = JSON.parse(cls.subjects || "[]");
        subjectsInClass.forEach((subject) => {
          acc.push({
            subject: subject.name,
            class: cls.Name,
          });
        });
        return acc;
      }, []);
      setSubjects(allSubjects);
    }
  }, [classes]);

  useEffect(() => {
    if (students && selectedClass) {
      const filtered = students.filter(
        (student) => student.class === selectedClass
      );
      setFilteredStudents(filtered);
    } else {
      setFilteredStudents(students); // Reset if no class is selected
    }
  }, [students, selectedClass]);

  // Existing handleInputChange, handleSubmit, calculateTotal, calculateGrade, handleSave functions...
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "exam_id") {
      setSelectedTerm(value);
    } else if (name === "class_id") {
      setSelectedClass(value);
    }
  };

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setFilterApplied(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting Marks Data:", marksData);
    for (const studentId in marksData) {
      for (const subject in marksData[studentId]) {
        await handleSave(studentId, subject);
      }
    }
  };
  const calculateTotal = (subject) => {
    const {
      first_ca = 0,
      second_ca = 0,
      assignment = 0,
      project = 0,
      exam = 0,
    } = subject;

    return (
      parseInt(first_ca) +
      parseInt(second_ca) +
      parseInt(assignment) +
      parseInt(project) +
      parseInt(exam)
    );
  };

  const calculateGrade = (total) => {
    if (total >= 75) return "A";
    if (total >= 65) return "B";
    if (total >= 50) return "C";
    if (total >= 40) return "D";
    return "F";
  };

  let totalMarkOfStudent;

  const calculateSummary = (studentSubjects) => {
    // Filter subjects by selected class
    const filteredSubjects = subjects.filter(
      (subject) => subject.class === selectedClass
    );

    // Calculate total obtainable marks
    const totalMarksObtainable = filteredSubjects.reduce((acc, subject) => {
      return acc + (subject.maxMarks || 100);
    }, 0);
    totalMarkOfStudent = totalMarksObtainable;

    // Calculate marks obtained by the student
    const marksObtained = filteredSubjects.reduce((acc, subject) => {
      const studentSubject = studentSubjects.find(
        (subj) => subj.subject === subject.subject
      );

      if (studentSubject) {
        const total = calculateTotal(studentSubject);
        return acc + total;
      }
      return acc;
    }, 0);

    const percentage = (marksObtained / totalMarksObtainable) * 100;
    let grade;
    if (percentage >= 90) grade = "A1";
    else if (percentage >= 80) grade = "B2";
    else if (percentage >= 70) grade = "C3";
    else grade = "F9";

    // Determine Exam Grade based on Percentage
    const determine = (percentage) => {
      if (percentage >= 75) return "A1";
      if (percentage >= 70) return "B2";
      if (percentage >= 65) return "B3";
      if (percentage >= 60) return "C4";
      if (percentage >= 55) return "C5";
      if (percentage >= 50) return "C6";
      if (percentage >= 45) return "D7";
      if (percentage >= 40) return "E8";
      return "F9";
    };

    const examGrade = determine(percentage);

    return { marksObtained, examGrade, percentage, grade };
  };

  const handleSave = async (studentId, subject) => {
    if (marksData[studentId] && marksData[studentId][subject]) {
      const studentData = marksData[studentId][subject];
      const total = calculateTotal(studentData);
      const grade = calculateGrade(total);
      try {
        await addResult({
          studentId,
          selectedTerm,
          selectedClass,
          subject,
          inputs: studentData,
          calculatedTotal: total,
          calculatedGrade: grade,
        });
        toast.success("Saved successfully");
      } catch (error) {
        toast.error("Not saved");
      }
    }
  };

  useEffect(() => {
    if (results && selectedTerm && selectedClass) {
      const organizedResults = {};

      // Filter results by selected term and class
      const filteredResults = results.filter(
        (result) =>
          result.selected_term === selectedTerm &&
          result.selected_class === selectedClass
      );

      filteredResults.forEach((result) => {
        const { student_id, subject } = result;
        if (!organizedResults[student_id]) {
          organizedResults[student_id] = {};
        }
        organizedResults[student_id][subject] = result;
      });
      setMarksData(organizedResults);
    } else {
      setMarksData({});
    }
  }, [results, selectedTerm, selectedClass]);

  return (
    <SideBar>
      <div className="container-fluid">
        <h3>Marks Register</h3>

        {/* Filter Form */}
        <div className="row">
          <div className="col-md-12">
            <div className="box box-primary">
              <div className="box-header with-border">
                <h3 className="box-title">Search Marks Register</h3>
              </div>
              <form onSubmit={handleFilterSubmit}>
                <div className="box-body">
                  <div className="row">
                    <div className="form-group col-md-4 pb-3">
                      <select
                        className="form-control"
                        name="exam_id"
                        value={selectedTerm}
                        onChange={handleFilterChange}
                        required
                      >
                        <option value="">Select Term</option>
                        {terms?.map((term) => (
                          <option key={term.id} value={term.id}>
                            {term.term_name === "1"
                              ? "FIRST TERM"
                              : term.term_name === "2"
                              ? "SECOND TERM"
                              : term.term_name === "3"
                              ? "THIRD TERM"
                              : "UNKNOWN TERM"}{" "}
                            {term.date}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group col-md-4 pb-3">
                      <select
                        className="form-control"
                        name="class_id"
                        value={selectedClass}
                        onChange={handleFilterChange}
                        required
                      >
                        <option value="">Select Class</option>
                        {classes?.map((cls) => (
                          <option key={cls.id} value={cls.Name}>
                            {cls.Name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group col-md-4 pt-1">
                      <button className="btn btn-primary" type="submit">
                        Search
                      </button>
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => {
                          setSelectedTerm("");
                          setSelectedClass("");
                          setFilterApplied(false);
                          setFilteredStudents(students); // Reset filtered students
                        }}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Conditional Rendering */}
        {filterApplied && filteredStudents.length > 0 && (
          <div className="row" style={{ overflowX: "auto" }}>
            <form onSubmit={handleSubmit} className="form-group w-100">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>Student Name</th>
                    <th>Subject</th>
                    <th>CA 1</th>
                    <th>CA 2</th>
                    <th>CA 3</th>
                    <th>CA 4</th>
                    <th>Exam</th>
                    <th>Total</th>
                    <th>Grade</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredStudents.map((student) => {
                    const studentId = student.userid;

                    if (!studentId) {
                      console.error("Student ID is missing:", student);
                      return null;
                    }

                    // Filter subjects based on the selected class
                    const studentSubjects = subjects
                      .filter((subject) => subject.class === selectedClass)
                      .map((subject) => {
                        const studentMarks =
                          marksData[studentId]?.[subject.subject] || {};
                        const total = calculateTotal(studentMarks);
                        const grade = calculateGrade(total);

                        return (
                          <React.Fragment
                            key={`${studentId}-${subject.subject}`}
                          >
                            <tr>
                              <td>{student.username}</td>
                              <td>
                                <b>{subject.subject}</b>
                              </td>
                              <td>
                                <span
                                  className="text-center"
                                  style={{ width: "80px", height: "40px" }}
                                >
                                  {studentMarks.first_ca || ""}
                                </span>
                              </td>
                              <td>
                                <span
                                  className="text-center"
                                  style={{ width: "80px", height: "40px" }}
                                >
                                  {studentMarks.second_ca || ""}
                                </span>
                              </td>
                              <td>
                                <span
                                  className="text-center"
                                  style={{ width: "80px", height: "40px" }}
                                >
                                  {studentMarks.assignment || ""}
                                </span>
                              </td>
                              <td>
                                <span
                                  className="text-center"
                                  style={{ width: "80px", height: "40px" }}
                                >
                                  {studentMarks.project || ""}
                                </span>
                              </td>
                              <td>
                                <span
                                  className="text-center"
                                  style={{ width: "80px", height: "40px" }}
                                >
                                  {studentMarks.exam || ""}
                                </span>
                              </td>
                              <td>
                                <b>{total}</b>
                              </td>
                              <td>
                                <b>{grade}</b>
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      });

                    const { marksObtained, examGrade, percentage } =
                      calculateSummary(
                        subjects.map(
                          (subject) =>
                            marksData[studentId]?.[subject.subject] || {}
                        )
                      );

                    // Return nothing if there are no subjects
                    if (studentSubjects.length === 0) {
                      return null;
                    }

                    return (
                      <React.Fragment key={student.id}>
                        {studentSubjects}
                        <tr>
                          <td
                            colSpan="8"
                            className="text-primary"
                            style={{ textAlign: "center" }}
                          >
                            <b
                              className="text-uppercase"
                              style={{ fontSize: "16px" }}
                            >
                              Marks Obtainable: {totalMarkOfStudent}
                            </b>

                            <b
                              className="text-uppercase"
                              style={{
                                fontSize: "16px",
                                paddingLeft: "16px",
                              }}
                            >
                              Marks Obtained: {marksObtained}
                            </b>

                            <b
                              style={{
                                fontSize: "16px",
                                paddingLeft: "16px",
                              }}
                            >
                              PERCENTAGE: {percentage.toFixed(1)}%
                            </b>

                            <b
                              style={{
                                fontSize: "16px",
                                paddingLeft: "16px",
                              }}
                            >
                              EXAM GRADE: {examGrade}
                            </b>
                          </td>
                          <td colSpan="3">
                            <NavLink
                              to={`/printresult?studId=${studentId}&&class=${selectedClass}&&term=${selectedTerm}`}
                              className="btn btn-primary pull-right"
                              target="_blank"
                            >
                              PRINT
                            </NavLink>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
            </form>
          </div>
        )}
      </div>
    </SideBar>
  );
};

export default ExamResult;
