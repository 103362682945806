import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  useGetResultQuery,
  useGetStudentQuery,
  useGetTermQuery,
} from "../hooks/result";
import { useStudentsQuery } from "../hooks/studentHook";
import {
  useFetchSchoolSettings,
  usePsychomotorByStudentAndClassQuery,
} from "../hooks/admin";

const Result = () => {
  const location = useLocation();
  const [studentId, setStudentId] = useState(null);
  const [selectedClass, setSelectedClass] = useState("");
  const [term, setTerm] = useState("");
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isParamsSet, setIsParamsSet] = useState(false);

  // Extract query parameters from URL
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const studId = searchParams.get("studId");
    const className = searchParams.get("class");
    const termValue = searchParams.get("term");

    if (studId && className && termValue) {
      setStudentId(studId);
      setSelectedClass(className);
      setTerm(termValue);
      setIsParamsSet(true);
    }
  }, [location]);

  // Fetch data only when all parameters are available
  const {
    data: result,
    isLoading,
    error,
  } = useGetResultQuery({
    studentId,
    selectedClass,
    selectedTerm: term,
    enabled: isParamsSet,
  });

  const { data: student } = useGetStudentQuery({
    studentId,
    enabled: isParamsSet,
  });

  const { data: psycho } = usePsychomotorByStudentAndClassQuery(
    studentId,
    selectedClass
  );

  const partA = psycho ? JSON.parse(psycho.part_a) : {};
  const partB = psycho ? JSON.parse(psycho.part_b) : {};

  // Extract remarks with fallback
  const classTeacherRemark =
    psycho?.class_teacher_remark || "No remark available";
  const headTeacherRemark =
    psycho?.head_teacher_remark || "No remark available";

  const partAFields = [
    "Aesthetic",
    "Creativity",
    "Honesty",
    "Responsibility",
    "Initiative",
    "Perseverance",
    "Leadership",
    "Obedience",
    "Politeness",
    "Self Control",
  ];

  const partBFields = [
    "Communication",
    "Handwriting",
    "Musical Skills",
    "Handling of Tools",
    "Painting/Drawing",
    "Sports/Game",
  ];

  const { data: settings } = useFetchSchoolSettings();

  const { data: students } = useStudentsQuery();

  const { data: currentTerm } = useGetTermQuery({
    id: term,
    enabled: isParamsSet,
  });

  // Get the length of students in the same class
  let sameClassCount = 0;
  if (students && student) {
    sameClassCount = students.filter(
      (stud) => stud.class === student[0].class
    ).length;
  }

  useEffect(() => {
    if (result && student && students && settings) {
      setIsDataFetched(true);
    }
  }, [result, student, psycho, settings, students, currentTerm]);

  useEffect(() => {
    if (isDataFetched) {
      window.print();
    }
  }, [isDataFetched, result]);

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error fetching result: {error.message}</p>;

  // Calculate Grand Total and Maximum Possible Score
  const totalScore = result?.reduce(
    (acc, res) => acc + res.calculated_total,
    0
  );
  const maxPossibleScore = result?.length * 100;

  // Calculate Percentage
  const percentage = ((totalScore / maxPossibleScore) * 100).toFixed(1);

  // Function to determine grade and description based on total score
  const determineGrade = (total) => {
    if (total >= 75 && total <= 100) {
      return { grade: "A1", description: "Excellent" };
    } else if (total >= 70 && total <= 74) {
      return { grade: "B2", description: "Very Good" };
    } else if (total >= 65 && total <= 69) {
      return { grade: "B3", description: "Good" };
    } else if (total >= 60 && total <= 64) {
      return { grade: "C4", description: "Credit" };
    } else if (total >= 55 && total <= 59) {
      return { grade: "C5", description: "Credit" };
    } else if (total >= 50 && total <= 54) {
      return { grade: "C6", description: "Credit" };
    } else if (total >= 45 && total <= 49) {
      return { grade: "D7", description: "Pass" };
    } else if (total >= 40 && total <= 44) {
      return { grade: "E8", description: "Pass" };
    } else if (total >= 0 && total <= 39) {
      return { grade: "F9", description: "Fail" };
    } else {
      return { grade: "N/A", description: "Invalid Score" };
    }
  };

  // Determine Exam Grade based on Percentage
  const determine = (percentage) => {
    if (percentage >= 75) return "A1";
    if (percentage >= 70) return "B2";
    if (percentage >= 65) return "B3";
    if (percentage >= 60) return "C4";
    if (percentage >= 55) return "C5";
    if (percentage >= 50) return "C6";
    if (percentage >= 45) return "D7";
    if (percentage >= 40) return "E8";
    return "F9";
  };

  const examGrade = determine(percentage);

  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className="mt-2 p-4 "
          style={{
            width: "100%",

            marginBottom: "20px",
            paddingBottom: 100,
          }}
        >
          {/* School Header Section */}
          <table className="table  w-100 ">
            <tbody>
              <tr>
                {/* School Logo */}
                <td width="5%">
                  <img
                    src="/img/logo.jpg"
                    alt="School Logo"
                    style={{ width: "88px", objectFit: "contain" }}
                  />
                </td>

                {/* School Information  */}
                <td width="85%" style={{ textAlign: "center" }}>
                  <h1
                    style={{
                      fontSize: "20px",
                      fontWeight: 700,
                      color: "#344767",
                    }}
                  >
                    {settings?.school_name}
                  </h1>
                  <p
                    style={{
                      color: "#344767",
                      margin: "5px 0",
                    }}
                  >
                    <div
                      style={{ fontSize: "20px" }}
                      dangerouslySetInnerHTML={{
                        __html: settings?.school_address,
                      }}
                    />
                  </p>
                  <p
                    style={{
                      color: "#344767",
                      margin: "5px 0",
                      fontSize: "14px",
                    }}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: settings?.school_address_second,
                      }}
                    />
                  </p>
                </td>

                {/* Student Picture */}
                <td width="10%">
                  <img
                    src="/img/person.jfif"
                    alt="Student"
                    style={{ width: "70px", height: "80px", margin: "10px" }}
                  />
                </td>
              </tr>
            </tbody>
          </table>

          {/* Student Information  Section */}
          <div
            style={{
              padding: "10px",
              marginBottom: "20px",
              borderBottom: "1px solid gray",
              borderTop: "1px solid gray",
            }}
          >
            <table className="table  w-100">
              <tbody>
                <tr>
                  {/* Student's Details */}
                  <td>
                    <p style={{ fontSize: "14px", margin: "5px 0" }}>
                      <b style={{ color: "#344767" }}>STUDENT'S NAME:</b>{" "}
                      <span>{student && student[0]?.username}</span>
                    </p>
                    <p style={{ fontSize: "14px", margin: "5px 0" }}>
                      <b style={{ color: "#344767" }}>GENDER:</b>{" "}
                      <span>{student && student[0]?.gender} </span>
                    </p>
                    <p style={{ fontSize: "14px", margin: "5px 0" }}>
                      <b style={{ color: "#344767" }}>RELIGION:</b>{" "}
                      <span>{student && student[0]?.religion}</span>
                    </p>
                    <p style={{ fontSize: "14px", margin: "5px 0" }}>
                      <b style={{ color: "#344767" }}>DATE OF BIRTH:</b>{" "}
                      <span>
                        {student &&
                          new Date(student[0].date_of_birth).toLocaleDateString(
                            "en-US",
                            {
                              year: "2-digit",
                              month: "numeric",
                              day: "numeric",
                            }
                          )}
                      </span>
                    </p>
                  </td>

                  {/* Class Information  */}
                  <td>
                    <p style={{ fontSize: "14px", margin: "5px 0" }}>
                      <b style={{ color: "#344767" }}>CLASS:</b>{" "}
                      <span>{student && student[0]?.class}</span>
                    </p>
                    <p style={{ fontSize: "14px", margin: "5px 0" }}>
                      <b style={{ color: "#344767" }}>NO. IN CLASS:</b>{" "}
                      <span>{sameClassCount}</span>
                    </p>
                    <p style={{ fontSize: "14px", margin: "5px 0" }}>
                      <b style={{ color: "#344767" }}>ADMISSION NO.:</b>{" "}
                      <span className="pull-right">
                        SF/
                        {student && student.length > 0 && student[0]?.createdAt
                          ? new Date(student[0].createdAt).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                              }
                            )
                          : "N/A"}
                        /
                        {student && student.length > 0
                          ? student[0]?.userid
                          : "0"}
                      </span>
                    </p>
                    <p style={{ fontSize: "14px", margin: "5px 0" }}>
                      <b style={{ color: "#344767" }}>RESUMPTION:</b>{" "}
                      <span>
                        {settings &&
                          new Date(settings?.resumption).toLocaleDateString(
                            "en-US",
                            {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            }
                          )}
                      </span>
                    </p>
                  </td>

                  {/* Exam Performance */}
                  <td width="32%">
                    <p style={{ fontSize: "14px", margin: "5px 0" }}>
                      <b style={{ color: "#344767" }}>TOTAL SCORE:</b>
                      <span>
                        {totalScore} / {maxPossibleScore}
                      </span>
                    </p>
                    <p style={{ fontSize: "14px", margin: "5px 0" }}>
                      <b style={{ color: "#344767" }}>PERCENTAGE:</b>{" "}
                      <span>{percentage}%</span>
                    </p>
                    <p style={{ fontSize: "14px", margin: "5px 0" }}>
                      <b style={{ color: "#344767" }}>EXAM GRADE:</b>{" "}
                      <span>{examGrade}</span>
                    </p>
                    <p style={{ fontSize: "14px", margin: "5px 0" }}>
                      <b style={{ color: "#344767" }}>TERM:</b>{" "}
                      <span>
                        {currentTerm && currentTerm.length > 0
                          ? currentTerm[0]?.term_name === "1"
                            ? "FIRST TERM"
                            : currentTerm[0]?.term_name === "2"
                            ? "SECOND TERM"
                            : currentTerm[0]?.term_name === "3"
                            ? "THIRD TERM"
                            : "UNKNOWN TERM"
                          : "Term data not available"}
                        {currentTerm && currentTerm[0]?.date}
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Report Card Section */}
          <div>
            <h5 className="text-center">EXAM REPORT CARD</h5>
            <table className="table table-bordered  w-100">
              <thead>
                <tr
                  className="black"
                  style={{
                    backgroundColor: "#e96793",
                    border: "1px solid #e96793",
                  }}
                >
                  <th className="px-2">Subject</th>
                  <th className="text-center">CA 1</th>
                  <th className="text-center">CA 2</th>
                  <th className="text-center">CA 3</th>
                  <th className="text-center">CA 4</th>
                  <th className="text-center">Exam</th>
                  <th className="text-center">Total</th>
                  <th className="text-center">Grade</th>
                  <th style={{ width: "95px", textAlign: "center" }}>Remark</th>
                </tr>
              </thead>
              <tbody>
                {result?.map((res, index) => {
                  const { grade, description } = determineGrade(
                    res.calculated_total
                  );
                  return (
                    <tr key={index}>
                      <td style={{ paddingLeft: "5px" }}>{res.subject}</td>
                      <td className="text-center">{res.first_ca}</td>
                      <td className="text-center">{res.second_ca}</td>
                      <td className="text-center">{res.assignment}</td>
                      <td className="text-center">{res.project}</td>
                      <td className="text-center">{res.exam}</td>
                      <td className="text-center">{res.calculated_total}</td>
                      <td className="text-center">{grade}</td>
                      <td className="text-center">{description}</td>
                    </tr>
                  );
                })}

                <tr>
                  <td colSpan="2" className="ps-3 text-center">
                    <b className="mb-0 " style={{ color: "#344767" }}>
                      GRAND TOTAL: {totalScore} / {maxPossibleScore}
                    </b>
                  </td>
                  <td colSpan="5" className="ps-3 text-center">
                    <b className="mb-0 " style={{ color: "#344767" }}>
                      PERCENTAGE: {percentage}%
                    </b>
                  </td>
                  <td colSpan="3" className="ps-3 text-center">
                    <b className="mb-0 " style={{ color: "#344767" }}>
                      EXAM GRADE: {examGrade}
                    </b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* grades analysis */}
        <div
          style={{
            width: "100%",
            marginTop: -50,
          }}
        >
          {/* Psychomotor Table */}
          <table className="mt table table-bordered w-100">
            <thead>
              <tr style={{ backgroundColor: "#e91e63" }}>
                <th className="text-center text-black py-1">PSYCHOMOTOR</th>
                {partBFields.map((field, index) => (
                  <th key={index} className="text-center text-black">
                    {field}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="text-xxs ps-3 text-center">
              <tr>
                <td
                  style={{
                    border: "1px solid lightgray",
                    margin: "0px",
                    textAlign: "center",
                    color: "#344767",
                  }}
                >
                  RATING
                </td>
                {partBFields.map((field, index) => (
                  <td
                    key={index}
                    style={{
                      border: "1px solid lightgray",
                      textAlign: "center",
                      color: "#344767",
                    }}
                  >
                    {partB[field] || "N/A"}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>

          {/* Behaviors Table */}
          <table
            className="table table-bordered w-100"
            style={{ marginTop: "-15px" }}
          >
            <thead>
              <tr style={{ backgroundColor: "#e91e63" }}>
                <th className="text-center text-black py-1">BEHAVIORS</th>
                {partAFields.map((field, index) => (
                  <th key={index} className="text-center text-black">
                    {field}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="text-xxs ps-3 text-center">
              <tr>
                <td
                  style={{
                    border: "1px solid lightgray",
                    textAlign: "center",
                    color: "#344767",
                  }}
                >
                  RATING
                </td>
                {partAFields.map((field, index) => (
                  <td
                    key={index}
                    style={{
                      border: "1px solid lightgray",
                      textAlign: "center",
                      color: "#344767",
                    }}
                  >
                    {partA[field] || "N/A"}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>

          {/* Grading Scale Table */}
          <table
            className="table table-bordered w-100"
            style={{ marginTop: "-16px" }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    border: "1px solid lightgray",
                    textAlign: "center",
                    color: "#344767",
                  }}
                >
                  GRADING SCALE
                </th>
                <th
                  style={{
                    border: "1px solid lightgray",
                    textAlign: "center",
                    color: "#344767",
                  }}
                >
                  75 - 100
                </th>
                <th
                  style={{
                    border: "1px solid lightgray",
                    textAlign: "center",
                    color: "#344767",
                  }}
                >
                  70 - 74
                </th>
                <th
                  style={{
                    border: "1px solid lightgray",
                    textAlign: "center",
                    color: "#344767",
                  }}
                >
                  65 - 69
                </th>
                <th
                  style={{
                    border: "1px solid lightgray",
                    textAlign: "center",
                    color: "#344767",
                  }}
                >
                  60 - 64
                </th>
                <th
                  style={{
                    border: "1px solid lightgray",
                    textAlign: "center",
                    color: "#344767",
                  }}
                >
                  55 - 59
                </th>
                <th
                  style={{
                    border: "1px solid lightgray",
                    textAlign: "center",
                    color: "#344767",
                  }}
                >
                  50 - 54
                </th>
                <th
                  style={{
                    border: "1px solid lightgray",
                    textAlign: "center",
                    color: "#344767",
                  }}
                >
                  45 - 49
                </th>
                <th
                  style={{
                    border: "1px solid lightgray",
                    textAlign: "center",
                    color: "#344767",
                  }}
                >
                  40 - 44
                </th>
                <th
                  style={{
                    border: "1px solid lightgray",
                    textAlign: "center",
                    color: "#344767",
                  }}
                >
                  0 - 39
                </th>
              </tr>
            </thead>
            <tbody className="text-xxs ps-3 text-center">
              <tr>
                <td
                  style={{
                    border: "1px solid lightgray",
                    textAlign: "center",
                    color: "#344767",
                  }}
                >
                  GRADE
                </td>
                <td
                  style={{
                    border: "1px solid lightgray",
                    color: "#344767",
                  }}
                >
                  A1
                </td>
                <td
                  style={{
                    border: "1px solid lightgray",
                    color: "#344767",
                  }}
                >
                  B2
                </td>
                <td
                  style={{
                    border: "1px solid lightgray",
                    color: "#344767",
                  }}
                >
                  B3
                </td>
                <td
                  style={{
                    border: "1px solid lightgray",
                    color: "#344767",
                  }}
                >
                  C4
                </td>
                <td
                  style={{
                    border: "1px solid lightgray",
                    color: "#344767",
                  }}
                >
                  C5
                </td>
                <td
                  style={{
                    border: "1px solid lightgray",
                    color: "#344767",
                  }}
                >
                  C6
                </td>
                <td
                  style={{
                    border: "1px solid lightgray",
                    color: "#344767",
                  }}
                >
                  D7
                </td>
                <td
                  style={{
                    border: "1px solid lightgray",
                    color: "#344767",
                  }}
                >
                  E8
                </td>
                <td
                  style={{
                    border: "1px solid lightgray",
                    color: "#344767",
                  }}
                >
                  F9
                </td>
              </tr>
            </tbody>
          </table>
          {/* REMARK */}
          <div className="col-md-12" style={{ marginTop: "-16px" }}>
            <div className="row">
              <table className="table table-bordered table-scrow w-100">
                <thead>
                  <tr style={{ backgroundColor: "#e91e63" }}>
                    <th className="text-center text-black">
                      <b>CLASS TEACHER'S REMARK</b>
                    </th>
                    <th className="text-center text-black">
                      <b>HEAD TEACHER/PRINCIPAL'S COMMENT</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        border: "1px solid lightgray",
                        textAlign: "center",
                        color: "#344767",
                        padding: "0 5px",
                      }}
                    >
                      <span className="text-uppercase">
                        {classTeacherRemark}
                      </span>
                    </td>
                    <td
                      style={{
                        border: "1px solid lightgray",
                        textAlign: "center",
                        width: "50%",
                        color: "#344767",
                      }}
                    >
                      <span className="text-uppercase">
                        {headTeacherRemark}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-12 " style={{ marginTop: "-20px" }}>
            <div className="row">
              <table className="table table-bordered table-scrow w-100">
                <thead>
                  <tr
                    style={{
                      backgroundColor: "#e91e63",
                    }}
                  >
                    <th className=" text-black text-center">
                      <b>STAMP/SIGNATURE</b>
                    </th>
                    <th className="text-black text-center">
                      <b>NOTICE</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        border: "1px solid lightgray",
                        textAlign: "center",
                      }}
                    >
                      <img
                        style={{ height: "100px", width: "100px" }}
                        src={settings?.stamp}
                        alt="Stamp/Signature"
                      />
                    </td>
                    <td
                      style={{
                        border: "1px solid lightgray",
                        textAlign: "center",
                        color: "#344767",
                      }}
                    >
                      <ol style={{ textAlign: "left" }}>
                        <li style={{ fontSize: "15px" }}>
                          Students are expected to print out the original report
                          card and keep it neat and clean.
                        </li>
                        <li style={{ fontSize: "15px" }}>
                          In case this report card is misplaced, a duplicate
                          copy can only be issued after payment of extra fee.
                        </li>
                        <li style={{ fontSize: "15px" }}>
                          For any complaint at any time kindly contact the
                          school personally.
                        </li>
                      </ol>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Result;
