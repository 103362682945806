import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useAddOrUpdateStudentMutation } from "../hooks/admin";
import { useClassesQuery } from "../hooks/classHook";
import { convertToBase64 } from "../utils";

const AddStudentModal = ({ show, handleClose, editingStudent }) => {
  const [classId, setClassId] = useState("");
  const [loading, setLoading] = useState(false);

  const { data: classes } = useClassesQuery();
  const { mutateAsync: addstudent } = useAddOrUpdateStudentMutation();

  const [formData, setFormData] = useState({
    userid: "",
    username: "",
    firstName: "",
    class: "",
    gender: "",
    date_of_birth: "",
    religion: "",
    profile_pic: "",
    status: "",
    email: "",
    password: "",
  });

  const handleClassChange = (e) => {
    const selectedClassId = parseInt(e.target.value, 10);
    const selectedClassName = classes?.find(
      (classItem) => classItem.SN === selectedClassId
    )?.Name;

    setClassId(selectedClassId);
    setFormData((prevData) => ({
      ...prevData,
      class: selectedClassName || "",
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = async (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    if (file) {
      const base64 = await convertToBase64(file);
      setFormData((prevData) => ({
        ...prevData,
        [name]: base64,
      }));
    }
  };

  const resetFormData = () => {
    setFormData({
      userid: "",
      username: "",
      firstName: "",
      class: "",
      gender: "",
      date_of_birth: "",
      religion: "",
      profile_pic: "",
      status: "",
      email: "",
      password: "",
    });
    setClassId("");
  };

  useEffect(() => {
    if (editingStudent) {
      const matchingClass = classes?.find(
        (classItem) => classItem.Name === editingStudent.class
      );
      const formattedDOB = new Date(editingStudent.date_of_birth)
        .toISOString()
        .split("T")[0];
      setFormData({
        userid: editingStudent.userid,
        username: editingStudent.username,
        firstName: editingStudent.firstName,
        class: editingStudent.class || "",
        gender: editingStudent.gender,
        date_of_birth: formattedDOB,
        religion: editingStudent.religion,
        status: editingStudent.status,
        password: "",
        email: editingStudent.email,
      });
      setClassId(matchingClass ? matchingClass.SN : "");
    } else {
      resetFormData();
    }
  }, [classes, editingStudent]);

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!classId) {
      toast.error("Please select a class");
      return;
    }
    setLoading(true);
    try {
      await addstudent(formData, editingStudent?.userid);
      handleClose();
      toast.success("Added successfully");
      resetFormData();
    } catch (error) {
      toast.error(
        "Something went wrong: " + (error.message || "Unknown error")
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={`modal ${show ? "show d-block" : ""}`}
      role="dialog"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", overflowY: "auto" }}
      onClick={handleBackdropClick}
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add New Student</h5>
            <button type="button" className="close" onClick={handleClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="box-body">
                <div className="row">
                  <div className="form-group no-margin col-md-6">
                    <label>
                      Full Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="username"
                      className="form-control"
                      placeholder="Enter Name"
                      value={formData.username}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group no-margin col-md-6">
                    <label>
                      First Name (Only First Name)
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      className="form-control"
                      placeholder="Enter Name"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group no-margin col-md-6">
                    <label>
                      Class <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-control w-100"
                      value={classId}
                      onChange={handleClassChange}
                      name="class"
                      required
                    >
                      <option value="">Select a class</option>
                      {classes &&
                        classes.map((classItem) => (
                          <option key={classItem.SN} value={classItem.SN}>
                            {classItem.Name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="form-group no-margin col-md-6">
                    <label>
                      Gender<span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-control"
                      value={formData.gender}
                      onChange={handleChange}
                      name="gender"
                      required
                    >
                      <option value="">Select Gender</option>
                      <option value="MALE">Male</option>
                      <option value="FEMALE">Female</option>
                      <option value="OTHER">Other</option>
                    </select>
                  </div>
                  <div className="form-group no-margin col-md-6">
                    <label>
                      Date of Birth <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="date"
                      name="date_of_birth"
                      className="form-control"
                      value={formData.date_of_birth}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group no-margin col-md-6">
                    <label>Religion</label>
                    <input
                      type="text"
                      name="religion"
                      className="form-control"
                      placeholder="Enter Religion"
                      value={formData.religion}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group no-margin col-md-6">
                    <label>Profile Picture</label>
                    <input
                      type="file"
                      name="profile_pic"
                      accept="image/*"
                      className="form-control"
                      onChange={handleFileChange}
                    />
                  </div>
                  <div className="form-group no-margin col-md-6">
                    <label>Status</label>
                    <select
                      className="form-control"
                      value={formData.status}
                      onChange={handleChange}
                      name="status"
                    >
                      <option value="">Select Status</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                  <div className="form-group no-margin col-md-6">
                    <label>
                      Email <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Enter Email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group no-margin col-md-6">
                    <label>
                      Password <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      placeholder="Enter the new of old password"
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleClose}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={loading}
                >
                  {loading ? "Submitting..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddStudentModal;
