import React, { useContext, useState } from "react";
import { CgSpinner } from "react-icons/cg";

import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { Store } from "../Store";
import { useSigninMutation } from "../services/auth";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state, dispatch } = useContext(Store);
  const { userInfo } = state;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { mutateAsync: signin, isPending } = useSigninMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await signin({ email, password });
      const { token, user, role, id } = response;

      if (token) {
        toast.success("Login successful");
        const userData = { token, user, role, id };
        dispatch({ type: "USER_SIGNIN", payload: userData });
        localStorage.setItem("userInfo", JSON.stringify(userData));
        const query = new URLSearchParams(location.search);
        const redirect = query.get("redirect");
        navigate(redirect || "/dashboard");
      }
    } catch (err) {
      console.error("Login failed:", err);
      toast.error("An error occurred");
    }
  };

  React.useEffect(() => {
    if (location.pathname === "/") {
      dispatch({ type: "USER_SIGNOUT" });
      localStorage.removeItem("userInfo");
    }
  }, [location.pathname, dispatch]);

  React.useEffect(() => {
    if (userInfo) {
      const query = new URLSearchParams(location.search);
      const redirect = query.get("redirect");
      navigate(redirect || "/dashboard");
    }
  }, [navigate, userInfo, location.search]);

  return (
    <div
      className="d-flex bg-secondary"
      style={{
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="col-lg-4 col-md-8 col-12">
        <div
          className="card z-index-0 fadeIn3 fadeInBottom"
          style={{ borderRadius: "15px", margin: "10px" }}
        >
          <div className="text-center pt-3 mb-0">
            <img
              src="/img/logo.jpg"
              alt="EduSaint"
              style={{
                width: "auto",
                height: "60px",
                borderRadius: "50%",
              }}
            />
          </div>
          <h4 className="text-dark font-weight-bolder text-center mt-2 mb-0 pt-2">
            Welcome Back!
          </h4>
          <h6 className="text-center text-sm">Let's get you signed in</h6>
          <div className="card-body">
            <form onSubmit={handleSubmit} className="text-start">
              <div className="input-group input-group-outline my-3">
                <input
                  type="email"
                  className="form-control"
                  value={email}
                  required
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="input-group input-group-outline mb-3">
                <input
                  type="password"
                  className="form-control"
                  value={password}
                  required
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="form-check form-switch d-flex align-items-center mb-3">
                <input
                  className="form-check-input"
                  name="remember"
                  type="checkbox"
                  id="rememberMe"
                />
                <label
                  className="form-check-label mb-0 ms-2"
                  htmlFor="rememberMe"
                >
                  Remember me
                </label>
              </div>
              <div className="text-center">
                {isPending ? (
                  <CgSpinner size={25} />
                ) : (
                  <button
                    type="submit"
                    className="btn w-100 my-4 mb-2"
                    style={{ backgroundColor: "#1e2600", color: "white" }}
                  >
                    Log in
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
