import React, { useContext, useState } from "react";
import toast from "react-hot-toast";
import SideBar from "../../Components/SideBar";
import { useSaveAttendanceMutation } from "../../hooks/admin";
import { useClassesQuery } from "../../hooks/classHook";
import { useStudentsQuery } from "../../hooks/studentHook";
import { useTeachersQuery } from "../../hooks/teacherHook";
import { Store } from "../../Store";

const AttendanceForm = ({
  setClassId,
  onSearch,
  onReset,
  attendanceDate,
  setAttendanceDate,
}) => {
  const {
    state: { userInfo },
  } = useContext(Store);
  const { data: classes } = useClassesQuery();

  const handleClassChange = (e) => {
    setClassId(e.target.value);
  };

  const handleDateChange = (e) => {
    setAttendanceDate(e.target.value); // Update the state with the selected date
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch();
  };

  return (
    <section className="content">
      <div className="row">
        <div className="col-md-12">
          <div className="box box-primary">
            <div className="box-header with-border">
              <h3 className="box-title">Search Attendance</h3>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="box-body">
                <div className="row">
                  <div className="form-group col-md-4 pb-3">
                    <label htmlFor="getClass">Class</label>
                    <select
                      className="form-control"
                      name="class_id"
                      id="getClass"
                      required
                      onChange={handleClassChange}
                    >
                      <option value="">Select Class</option>
                      {classes
                        ?.filter((cls) =>
                          JSON.parse(cls.teachers || "[]").some(
                            (teacher) => teacher.email === userInfo.user
                          )
                        )
                        .map((cls) => (
                          <option key={cls.id} value={cls.Name}>
                            {cls.Name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="form-group col-md-4 pb-3">
                    <label htmlFor="getAttendanceDate">Attendance Date</label>
                    <input
                      type="date"
                      className="form-control"
                      name="attendance_date"
                      id="getAttendanceDate"
                      required
                      value={attendanceDate} // Bind value to selected date
                      onChange={handleDateChange} // Update date on change
                    />
                  </div>

                  <div
                    className="form-group col-md-4"
                    style={{ paddingTop: "24px", paddingBottom: "20px" }}
                  >
                    <button className="btn btn-primary" type="submit">
                      Search
                    </button>
                    <button
                      type="button"
                      className="btn btn-success ml-3"
                      onClick={onReset}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

const AttendanceList = ({ students, attendanceDate }) => {
  const { mutateAsync: saveAttendance } = useSaveAttendanceMutation();
  const {
    state: { userInfo },
  } = useContext(Store);
  const { data: teachers } = useTeachersQuery();

  const user = teachers?.find(
    (teacher) => teacher.email === userInfo.user
  )?.username;

  const [attendanceData, setAttendanceData] = useState({});

  const handleAttendanceChange = (e, student) => {
    const attendanceStatus = e.target.value;
    setAttendanceData((prev) => {
      const updatedData = {
        ...prev,
        [student.id]: {
          studentName: student.username,
          class: student.class,
          attendanceStatus,
          collectedBy: user,
          date: attendanceDate, // Use selected attendance date
        },
      };
      console.log("Updated Attendance Data:", updatedData);
      return updatedData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submit triggered");
    try {
      const attendanceArray = Object.values(attendanceData);
      for (const attendance of attendanceArray) {
        await saveAttendance(attendance);
        console.log(attendance);
      }
      toast.success("Saved");
    } catch (error) {
      console.error(error);
      toast.error("Network error");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-12" style={{ overflowX: "auto" }}>
          <div className="box">
            <div className="box-header">
              <h3 className="box-title">Attendance List</h3>
            </div>
            <div className="w-100" style={{ overflowX: "auto" }}>
              {students.length > 0 ? (
                <table className="table w-100 table-hover">
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>Student Name</th>
                      <th>Attendance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {students.map((student, index) => (
                      <tr key={student.id}>
                        <td>{index + 1}</td>
                        <td>{student.username}</td>
                        <td>
                          {["Present", "Late", "Absent", "Half Day"].map(
                            (status) => (
                              <label
                                key={status}
                                style={{ marginRight: "20px" }}
                              >
                                <input
                                  type="radio"
                                  value={status}
                                  name={`attendance${student.id}`}
                                  onChange={(e) =>
                                    handleAttendanceChange(e, student)
                                  }
                                />{" "}
                                {status}
                              </label>
                            )
                          )}
                        </td>
                        <td>
                          <button className="btn btn-success" type="submit">
                            Save
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No students found. Please perform a search.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

const ClassAttendance = () => {
  const [classId, setClassId] = useState("");
  const [studentsInTeacherClasses, setStudentsInTeacherClasses] = useState([]);
  const [attendanceDate, setAttendanceDate] = useState(
    new Date().toISOString().split("T")[0]
  ); // default to current date
  const { data: students } = useStudentsQuery();
  const { data: classes } = useClassesQuery();
  const {
    state: { userInfo },
  } = useContext(Store);
  const [hasSearched, setHasSearched] = useState(false);

  const handleSearch = () => {
    if (classes && students) {
      const teacherClasses = classes.filter((cls) =>
        JSON.parse(cls.teachers || "[]").some(
          (teacher) => teacher.email === userInfo.user
        )
      );
      // Filter by selected classId if provided
      const filteredStudents = students.filter(
        (student) =>
          teacherClasses.some((cls) => cls.Name === student.class) &&
          (!classId || student.class === classId)
      );
      setStudentsInTeacherClasses(filteredStudents);
      setHasSearched(true);
    }
  };

  const handleReset = () => {
    setClassId("");
    setStudentsInTeacherClasses([]);
    setHasSearched(false);
  };

  return (
    <SideBar>
      <div className="container-fluid">
        <div className="card p-4">
          <AttendanceForm
            setClassId={setClassId}
            onSearch={handleSearch}
            onReset={handleReset}
            attendanceDate={attendanceDate}
            setAttendanceDate={setAttendanceDate}
          />
          {hasSearched && (
            <AttendanceList
              students={studentsInTeacherClasses}
              attendanceDate={attendanceDate}
            />
          )}
        </div>
      </div>
    </SideBar>
  );
};

export default ClassAttendance;
