import React, { useEffect, useState } from "react";
import SideBar from "../Components/SideBar";
import { convertToBase64 } from "../utils";
import {
  useFetchSchoolSettings,
  useUpdateSchoolSettingsMutation,
} from "../hooks/admin";
import toast from "react-hot-toast";

const Settings = () => {
  const mutation = useUpdateSchoolSettingsMutation();

  const { data, refetch } = useFetchSchoolSettings();

  const [formData, setFormData] = useState({
    school_name: "",
    school_address: "",
    school_address_second: "",
    resumption: "",
    stamp: "",
    logo: "",
  });

  useEffect(() => {
    if (data) {
      const formattedResumption = data.resumption
        ? new Date(data.resumption).toISOString().split("T")[0]
        : "";

      setFormData({
        ...data,
        resumption: formattedResumption,
      });
    }
  }, [data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = async (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    if (file) {
      const base64 = await convertToBase64(file);
      setFormData((prevData) => ({
        ...prevData,
        [name]: base64,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    mutation.mutate(formData, {
      onSuccess: () => {
        toast.success("Update successful");
      },
      onError: (error) => {
        toast.error("Update failed: " + error.message);
      },
    });
    refetch();
  };

  return (
    <SideBar>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card p-4">
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className="box-body">
                  <div className="form-group">
                    <label className="mb-0">School Name</label>
                    <input
                      style={{ border: "1px solid lightgray", padding: "6px" }}
                      type="text"
                      className="form-control"
                      name="school_name"
                      value={formData.school_name}
                      onChange={handleInputChange}
                      required
                      placeholder="School Name"
                    />
                  </div>

                  <div className="form-group">
                    <label className="mb-0">School Address</label>
                    <input
                      style={{ border: "1px solid lightgray", padding: "6px" }}
                      type="text"
                      className="form-control"
                      name="school_address"
                      value={formData.school_address}
                      onChange={handleInputChange}
                      required
                      placeholder="School Address"
                    />
                  </div>

                  <div className="form-group">
                    <label className="mb-0">School Address Line 2</label>
                    <input
                      style={{ border: "1px solid lightgray", padding: "6px" }}
                      type="text"
                      className="form-control"
                      name="school_address_second"
                      value={formData.school_address_second}
                      onChange={handleInputChange}
                      required
                      placeholder="School Address Line 2"
                    />
                  </div>

                  <div className="form-group">
                    <label className="mb-0">Resumption Date</label>
                    <input
                      style={{ border: "1px solid lightgray", padding: "6px" }}
                      type="date"
                      className="form-control"
                      name="resumption"
                      value={formData.resumption}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div class="form-group">
                        <label class="mb-0">
                          Logo<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          style={{
                            border: "1px solid lightgray",
                            padding: "6px",
                          }}
                          type="file"
                          class="form-control"
                          onChange={handleFileChange}
                          name="logo"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      {formData.logo && (
                        <img
                          src={formData.logo}
                          style={{ width: 100 }}
                          alt="logo"
                        />
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="mb-0">
                          School Stamp<span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          style={{
                            border: "1px solid lightgray",
                            padding: "6px",
                          }}
                          type="file"
                          className="form-control"
                          name="stamp"
                          onChange={handleFileChange}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      {formData.stamp && (
                        <img
                          src={formData.stamp}
                          alt="Fevicon Preview"
                          style={{ width: "100px" }}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <button type="submit" className="btn btn-primary">
                  Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </SideBar>
  );
};

export default Settings;
