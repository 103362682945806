/** @format */

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import apiClient from "../apiClient";

// Hook for fetching all students
export const useStudentsQuery = () => {
  return useQuery({
    queryKey: ["students"],
    queryFn: async () => {
      const { data } = await apiClient.get("api/students/all-students");
      return data;
    },
  });
};

// Hook for fetching a single student by userid
export const useStudentByIdQuery = (userid) => {
  return useQuery({
    queryKey: ["student", userid],
    queryFn: async () => {
      const { data } = await apiClient.get(`api/students/student/${userid}`);
      return data;
    },
    enabled: !!userid,
  });
};

// Hook for deleting a student by userid
export const useDeleteStudentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["userid"],
    mutationFn: async (userid) =>
      (await apiClient.delete(`api/students/${userid}`)).data,
    onSuccess: () => {
      queryClient.invalidateQueries(["student"]);
    },
  });
};

// Hook for adding a new class
export const useAddClassMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (newClass) =>
      (await apiClient.post("api/students", newClass)).data,
    onSuccess: () => {
      queryClient.invalidateQueries(["students"]);
    },
  });
};

// Hook for editing a class
export const useEditClassMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ id, updatedClass }) =>
      (await apiClient.put(`api/students/${id}`, updatedClass)).data,
    onSuccess: () => {
      queryClient.invalidateQueries(["students"]);
    },
  });
};

// Hook for deleting a class
export const useDeleteClassMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id) =>
      (await apiClient.delete(`api/students/${id}`)).data,
    onSuccess: () => {
      queryClient.invalidateQueries(["students"]);
    },
  });
};
