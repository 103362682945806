import React, { useContext, useState } from "react";
import SideBar from "../../Components/SideBar";
import ReactPaginate from "react-paginate";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Store } from "../../Store";
import { useClassesQuery } from "../../hooks/classHook";
import {
  useDeleteStudentMutation,
  useStudentsQuery,
} from "../../hooks/studentHook";

import AddStudentModal from "../../Components/AddStudentModal";
import { NavLink } from "react-router-dom";

const ITEMS_PER_PAGE = 10;
const TeacherStudent = () => {
  const { mutateAsync: deleteStudent } = useDeleteStudentMutation();

  const handleDelete = (userid) => {
    if (window.confirm("Are you sure you want to delete this student?")) {
      deleteStudent(userid);
    }
  };

  const { data: classes } = useClassesQuery();
  const { data: students } = useStudentsQuery();

  const [editingStudent, setEditingStudent] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const {
    state: { userInfo },
  } = useContext(Store);

  // Filter classes where the logged-in teacher is included
  const teacherClasses = classes?.filter((cls) =>
    JSON.parse(cls.teachers || "[]").some(
      (teacher) => teacher.email === userInfo.user
    )
  );

  // console.log("Teacher's Classes:", teacherClasses);

  // Filter students who belong to the teacher's classes
  const studentsInTeacherClasses = students?.filter((student) =>
    teacherClasses?.some((cls) => cls.Name === student.class)
  );

  // Extract subjects from the teacher's classes
  let subjects = [];
  teacherClasses?.forEach((cls) => {
    subjects = subjects.concat(JSON.parse(cls.subjects || "[]"));
  });

  const [currentPage, setCurrentPage] = useState(0);

  const handleShowModal = (term = null) => {
    setEditingStudent(term); // Set the editing term if provided
    setShowModal(true); // Show the modal
  };

  // Handle editing term
  const handleEditStudent = (term) => {
    handleShowModal(term); // Show the modal for editing
  };

  // Calculate the data to display on the current page
  const startOffset = currentPage * ITEMS_PER_PAGE;
  const endOffset = startOffset + ITEMS_PER_PAGE;
  const currentItems = studentsInTeacherClasses.slice(startOffset, endOffset);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };
  const boxStyle = {
    borderTop: "3px solid #3c8dbc",
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setEditingStudent(null);
  };

  return (
    <SideBar>
      <div className="container-fluid">
        <div class="card w-100 px-2 mb-3" style={boxStyle}>
          <div class="box-header with-border">
            <h3 class="box-title py-2">My Student</h3>
          </div>

          <div className="row" style={{ overflowX: "auto" }}>
            {/* {isPending ? <CgSpinner size={25} /> : ""} */}
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="text-uppercase" style={{ color: "black" }}>
                    SN
                  </th>
                  <th className="text-uppercase" style={{ color: "black" }}>
                    Student Record
                  </th>
                  <th className="text-uppercase" style={{ color: "black" }}>
                    Bio Data
                  </th>
                  <th className="text-uppercase" style={{ color: "black" }}>
                    Registered On
                  </th>
                  <th
                    className="text-uppercase text-center"
                    style={{ color: "black" }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <AddStudentModal
                show={showModal}
                handleClose={handleCloseModal}
                editingStudent={editingStudent}
              />
              <tbody>
                {currentItems.map((student, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <span className="text-primary">{student.username}</span>
                      <p style={{ color: "black" }}>
                        Admission No.:{" "}
                        <span className="text-secondary">
                          SFS/{new Date(student.createdAt).getFullYear()}/
                          {student.userid}
                        </span>
                      </p>
                      <p style={{ color: "black" }}>
                        Class:{" "}
                        <span className="text-secondary">{student.class}</span>
                      </p>
                    </td>
                    <td>
                      <p style={{ color: "black" }}>
                        DOB:{" "}
                        <span className="text-secondary">
                          {new Date(student.date_of_birth).toLocaleDateString(
                            "en-US",
                            {
                              year: "2-digit",
                              month: "numeric",
                              day: "numeric",
                            }
                          )}
                        </span>
                      </p>
                      <p style={{ color: "black" }}>
                        Gender:{" "}
                        <span className="text-secondary">{student.gender}</span>
                      </p>
                      <p style={{ color: "black" }}>
                        Religion:{" "}
                        <span className="text-secondary">
                          {student.religion}
                        </span>
                      </p>
                    </td>
                    <td>
                      <span className="text-secondary">
                        {new Date(student.createdAt).toLocaleDateString(
                          "en-US",
                          {
                            year: "2-digit",
                            month: "numeric",
                            day: "numeric",
                          }
                        )}
                      </span>
                    </td>
                    <td className="d-flex justify-content-center">
                      <NavLink to={`/psychomotor/${student.userid}`}>
                        <button className="btn btn-primary btn-sm ml-2">
                          PSYCHO
                        </button>
                      </NavLink>
                      <button
                        className="btn btn-success btn-sm text-black mx-2"
                        onClick={() => handleEditStudent(student)}
                      >
                        EDIT
                      </button>
                      <button
                        className="btn btn-danger btn-sm text-black"
                        onClick={() => handleDelete(student.userid)}
                      >
                        DELETE
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <ReactPaginate
            previousLabel={
              <div className="pagination-label">
                <FaArrowLeft />
              </div>
            }
            nextLabel={
              <div className="pagination-label">
                <FaArrowRight />
              </div>
            }
            breakLabel="..."
            pageCount={Math.ceil(
              studentsInTeacherClasses.length / ITEMS_PER_PAGE
            )}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName="pagination-container pagination justify-content-end mt-3"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            activeClassName="active"
          />
        </div>
      </div>
    </SideBar>
  );
};

export default TeacherStudent;
